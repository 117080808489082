<template>
  <div>
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>Academics</h5>
      </div>
    </div>
    <!--Banner Wrap End-->

    <section>
      <div class="container">
        <!--about_courses START-->
        <div class="about_courses">
          <!--about_courses_thumb START-->
          <div class="about_courses_thumb">

            <!--Tab Menu Wrap Start-->
            <div class="iq_tab_menu">
              <ul class="naac-tabs" id="tabs" data-tabs="tabs">
                <li class="tab-li" :class="{'active': key === 0}" v-for="(value, key) in documents" :key="key">
                  <a data-toggle="tab" :href="value.title | toLink">{{ value.title }}</a>
                </li>
              </ul>
            </div>

            <!--Tab Menu Wrap End-->
            <div id="my-tab-content" class="tab-content">
              <div class="tab-pane" :class="{'active': key === 0}" :id="value.title | toId" v-for="(value, key) in documents" :key="key">
                <div class="naac-title text-center">
                  <h5 class="text-capitalize">{{ value.name }}</h5>
                </div>
                <div v-if="value.tableData">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th v-for="item in tableFields" :key="item.key">{{ item.label }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in value.tableData" :key="index">
                      <td>{{ item.metric }}</td>
                      <td><a class="text-dark" :href="item.link" target="_blank">{{ item.parameter }}</a></td>
                      <td>{{ item.weightage }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="value.list">
                  <div v-for="item in value.list" :key="item.title">
                    <h5>{{ item.title }}</h5>
                    <ul v-for="link in item.links" :key="link">
                      <li><a class="text-dark" target="_blank" :href="link.link">{{ link.name }}</a></li>
                    </ul>
                    <br>
                  </div>
                </div>
                <div v-if="value.feedbackForms">
                  <div class="row">
                    <div class="col-md-3" v-for="item in value.feedbackForms" :key="item.name">
                      <a :href="item.link" target="_blank">
                        <div class="form-box">
                          <i :class="item.icon" aria-hidden="true"></i>
                          <div>{{ item.name }}</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--about_courses end-->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Academics',
  // ...
  data () {
    return {
      tableFields: [
        {
          key: 'metric',
          label: 'Metric'
        },
        {
          key: 'link',
          label: 'Parameter'
        },
        {
          key: 'weightage',
          label: 'Weightage'
        }
      ],
      documents: [
        {
          title: 'Exam Cell',
          name: 'Exam Cell'
        },
        {
          title: 'Curriculum & syllabus',
          name: 'Curriculum & syllabus'
        },
        {
          title: 'Student Centric Methods',
          name: 'Student Centric Methods',
          id: 'SCM'
        },
        {
          title: 'Results',
          name: 'Results'
        }
      ]
    }
  },
  filters: {
    toId (value) {
      return 'tab_' + value.toLowerCase().replace(/\s+/g, '-')
    },
    toLink (value) {
      return '#tab_' + value.toLowerCase().replace(/\s+/g, '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 35px;
}

.text-dark {
  color: #0f8f46;
  text-decoration: underline;
}

.naac-tabs {
  padding: 15px !important;

  li {
    margin: 10px !important;
    border: 1px solid #0f8f46 !important;
  }
}

.naac-title {
  background-color: #0f8f46;
  padding: 15px;
  margin-bottom: 20px;

  h5 {
    color: white !important;
  }
}

.form-box {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #0f8f46;
  text-align: center;
  cursor: pointer;
  color: #0f8f46;
  i {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.form-box:hover {
  background-color: #0f8f46;
  color: white !important;
}

.tab-li {
  width: 230px !important;
}
</style>
